import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-containerrr">
      <div className="loaderrr">
        <span className="loader-texttt">loading</span>
        <span className="loaddd"></span>
      </div>
    </div>
  );
};

export default Loader;
