import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Testimonials from "../components/Home/Testimonial";
import Games from "../components/Games";
import News from "../components/News.js";
import IMAGES from "../img/image.js";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Home.css";
import { message } from "antd";
import getUserData from "../utils/userDataService.js";
import { setUser } from "../redux/features/userSlice.js";
import { useDispatch } from "react-redux";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [display, setDisplay] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
      localStorage.setItem("giveaway", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("giveaway", "false");
  };

  return (
    <Layout>
      {showPopup && display === "yes" && (
        <div className="popup-container">
          <div>
            <CancelIcon onClick={handleClosePopup} className="icon" />
          </div>
          <Link target="_blank" to={link}>
            <img src={image} alt="popup-img" />
          </Link>
        </div>
      )}
      <HeroSection />
      <Games title={"Popular Games Top-Up"} />
    </Layout>
  );
};

export default Home;
